
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import {
  getPmtRoleInfo as getPmtRoleInfoAPI,
  savePmtRoleInfo as savePmtRoleInfoAPI
} from "@/API/closing";
export default defineComponent({
  setup() {
    const columns = [
      {
        width: 80,
        slots: { customRender: "pmtTitle" }
      },
      {
        dataIndex: "CBU",
        title: "CBU",
        width: 180,
        slots: { customRender: "cbu" }
      },
      {
        dataIndex: "PBP",
        title: "PBP",
        width: 180,
        slots: { customRender: "pbp" }
      },
      {
        dataIndex: "VAN",
        title: "VAN",
        width: 180,
        slots: { customRender: "van" }
      }
      // {
      //   dataIndex: 'DENZA',
      //   title: 'DENZA',
      //   width: 180,
      //   slots: { customRender: 'denza' }
      // }
    ];

    type Make = "CBU" | "PBP" | "VAN" | "DENZA";

    type PMT = "MBAFC" | "Program Accrual";

    interface PmtSetting {
      id?: number;
      value?: PMT;
    }

    interface RuleItemData {
      id?: number;
      make: Make;
      pmt: PMT;
      bizType: number;
    }

    const data = ref<Record<Make, PmtSetting>[]>([
      {
        CBU: {
          id: undefined,
          value: undefined
        },
        PBP: {
          id: undefined,
          value: undefined
        },
        VAN: {
          id: undefined,
          value: undefined
        },
        DENZA: {
          id: undefined,
          value: undefined
        }
      }
    ]);

    getPmtRoleInfoAPI().then((res: unknown) => {
      for (const ruleItemData of res as RuleItemData[]) {
        data.value[0][ruleItemData.make].id = ruleItemData.id;
        data.value[0][ruleItemData.make].value = ruleItemData.pmt;
      }
    });

    const handleSave = async () => {
      const ruleList = [];
      for (const make in data.value[0]) {
        const makePMT = data.value[0][make];
        if (!makePMT.value) {
          message.error("Not select the PMT rule!");
          return;
        }
        const ruleItemData: RuleItemData = {
          make: make as Make,
          pmt: makePMT.value,
          bizType: 1
        };
        if (makePMT.id) {
          ruleItemData.id = makePMT.id;
        }
        ruleList.push(ruleItemData);
      }

      await savePmtRoleInfoAPI(ruleList);
      message.success("Save successfully!");
    };

    return {
      columns,
      data,
      handleSave
    };
  }
});
